<template>
    <div class="modal-overlay" :style="{ zIndex: 1 }"></div>
    <div class="modal" role="dialog" tabindex="-1" :style="{ display: 'inline', zIndex: 2 }">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ props.title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="props.close">
                        <span class="fa fa-times" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    title: string;
    close: (payload: MouseEvent) => void;
}>();
</script>