<template>
	<div id="owner-quick-links" class="quick-links-table"></div>
	<div id="property-quick-links" class="quick-links-table"></div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import type { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import type { ColumnDefinition, CellComponent, } from "tabulator-tables";
import type { Owners } from "@/helpers/interface/candidates";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { ucwords, blankIf } from "@/helpers/common";
import { useAPI } from "@/helpers/services/api"

const api = useAPI()
const store = useCandidateDetailsStore();
const owners = computed((): Owners[] => store.getOwners)

interface QuickLinkItem {
	label: string,
	url: string
}

let remoteQuickLinks: QuickLinkItem[] | null
let ownerTabulator: Tabulator | null
let propertyTabulator: Tabulator | null


const ownerTableColumns = ref<ColumnDefinition[]>([
	{
		title: "Owner",
		field: "full_name",
		width: "60%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const owner = cell.getData() as Owners
			return `<div class="row list-row">
                    <div class="col-3">Full name: </div> <div class="col-9 text-truncate">${ucwords(owner.full_name)}</div>
                    <div class="col-3">Birth date: </div> <div class="col-9 text-truncate">${owner.birth_date}</div>
                    <div class="col-3">City: </div> <div class="col-9 text-truncate">${owner.city}</div>
                    <div class="col-3">State: </div> <div class="col-9 text-truncate">${owner.state}</div>
                </div>`;
		}
	},
	{
		title: "Quick Links",
		field: "full_name",
		width: "40%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const processName = (name: string): string => encodeURIComponent(ucwords(blankIf(name)))

			const joinNames = (names: string[], separator: string = " ", encode: boolean = false): string => {
				const joined = names.filter(name => name !== "").join(separator)
				return encode ? encodeURIComponent(joined) : joined
			}

			const owner: Owners = cell.getData() as Owners
			const firstName = processName(owner.first_name)
			const middleName = owner.commonality_score > 0.8 ? processName(owner.middle_name) : ""
			const lastName = processName(owner.last_name)
			const state = processName(owner.state)
			const city = blankIf(owner.city)

			const fullName = joinNames([firstName, middleName, lastName])
			const address = joinNames([city, state].filter(part => part !== ""), " ")

			const dob = encodeURIComponent(dayjs(owner.birth_date).toISOString())

			const baseGoogleKeywords = joinNames([fullName, address], " ", true)
			const obituaryKeywords = `${baseGoogleKeywords} obituary`
			const deathKeywords = `${baseGoogleKeywords} death`

			const fullNameAncestry = joinNames([joinNames([firstName, middleName], " ", true), lastName], "_", true)
			const firstAndMiddleNameLegacy = joinNames([firstName, middleName], " ", true)

			const links = [
				{
					label: "Google",
					url: `https://www.google.com/search?q=${baseGoogleKeywords}`
				},
				{
					label: "Google Obituary",
					url: `https://www.google.com/search?q=${obituaryKeywords}`
				},
				{
					label: "Google Death",
					url: `https://www.google.com/search?q=${deathKeywords}`
				},
				{
					label: "Facebook",
					url: `https://www.facebook.com/search/people?q=${fullName}`
				},
				{
					label: "LinkedIn",
					url: `https://www.linkedin.com/search/results/people/?keywords=${fullName}`
				},
				{
					label: "Ancestry",
					url: `https://www.ancestry.com/search/categories/34/?name=${fullNameAncestry}&birth=${dob}&priority=usa`
				},
				{
					label: "Legacy",
					url: `https://www.legacy.com/search?countryId=366899&countryUrl=united-states-of-america&dateRange=All&firstName=${firstAndMiddleNameLegacy}&lastName=${lastName}&stateUrl=${state}`
				},

			] as QuickLinkItem[]

			const parentDiv = document.createElement("div")
			links.forEach((link) => {
				const label = createQuickLink(link)
				parentDiv.appendChild(label)
			})

			return parentDiv
		}
	}
])


const propertyTabulatorTableColumns = ref<ColumnDefinition[]>([
	{
		title: "Property Quick Links",
		field: "items",
		width: "100%",
		visible: true,
		headerSort: false,
		formatter: function () {
			const container = document.createElement("div")
			if (remoteQuickLinks && remoteQuickLinks.length) {
				remoteQuickLinks.forEach(link => {
					const label = createQuickLink(link)
					container.appendChild(label)
				})
			}
			return container
		}
	}
])

const createQuickLink = (link: QuickLinkItem) => {
	const a = document.createElement("a")
	a.classList.add("text-white")
	a.classList.add("text-capitalize")
	a.href = link.url
	a.innerText = link.label
	a.target = "_blank"

	const label = document.createElement("label")
	label.classList.add("chips-container")
	label.appendChild(a)
	return label
}

const loadOwnerTabulator = async (data: Owners[] | undefined) => {
	ownerTabulator = await createTabulator("#owner-quick-links", {
		data: data,
		columns: ownerTableColumns.value,
		initialSort: [
			{ column: "first_name", dir: "desc" },
		],
		pagination: false,
		placeholder: "No records found.",
	})
}

const loadPropertyTabulator = async () => {
	remoteQuickLinks = await fetchRemoteQuickLinks()
	propertyTabulator = await createTabulator("#property-quick-links", {
		data: [{ "placeholder": true }],
		columns: propertyTabulatorTableColumns.value,
		pagination: false,
		placeholder: "No records found.",
	})
}


const fetchRemoteQuickLinks = async () => {
	if (!store.situsInfo?.tru_id)
		return []
	const { data } = await api.get(`taxroll/quick_links/${store.situsInfo.tru_id}`)
	return data as QuickLinkItem[]
}

onMounted(() => {
	loadOwnerTabulator(owners.value)
	loadPropertyTabulator()
})

onUnmounted(() => {
	destroyTabulator(ownerTabulator)
	destroyTabulator(propertyTabulator)
})

watch(() => owners.value, async (data) => {
	await loadOwnerTabulator(data)
})
</script>
