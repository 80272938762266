<template>
    <div class="position-relative">
        <label class="config-header">Config: Mail Template Configuration</label>
        <div class="actions-description">
            TrueRoll's Mail Module allows you to mark specific templates for mailing. These templates could be your
            standard
            questionnaires, denials, re-applications, or templates developed specifically for an exemption audit. Add
            your
            mail templates here so they become available in the Update menu on the Candidate Details page.
        </div>
        <div class="admin-config-mail-template mt-2">
            <div id="config-mail-template-list"></div>
        </div>
        <div class="position-absolute" :style="{ top: 0, right: 0 }">
            <button type="button" class="searchButton cbFormTextField-xl" @click="showNewMailTemp = true"
                v-if="hasPermissionToCreateMailTemplate">
                <span class="fa fa-plus-circle" aria-hidden="true"></span>
                New Template
            </button>
        </div>
        <teleport to="body">
            <ModalDialog v-if="showNewMailTemp" title="New Mail Template" :close="() => showNewMailTemp = false">
                <AdminConfigAddMailTemplate @add-new-mail-temp="() => fetchMailTemplatesList()" />
            </ModalDialog>

            <ModalDialog v-if="editMailTemplate" title="Edit Mail Template" :close="() => editMailTemplate = null">
                <AdminConfigEditMailTemplate @edit-mail-temp="() => fetchMailTemplatesList()"
                    :mailing="editMailTemplate" />
            </ModalDialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { ref, onMounted, onUnmounted, computed } from "vue"
import AdminConfigAddMailTemplate from "@/components/Admin/AdminConfigAddMailTemplate.vue"
import AdminConfigEditMailTemplate from "@/components/Admin/AdminConfigEditMailTemplate.vue"
import type { MailTemplates } from "@/helpers/interface/admin-page"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import type { TabulatorFull as Tabulator } from 'tabulator-tables'
import type { ColumnDefinition, CellComponent } from "tabulator-tables";
import { createAjaxTabulator, destroyTabulator } from "@/helpers/true-tabulator";
import { validateUserPermission } from "@/helpers/common";

const baseURL = import.meta.env.VITE_DEV_API_URL
const showNewMailTemp = ref(false)
const editMailTemplate = ref<MailTemplates | null>()
const hasPermissionToCreateMailTemplate = computed(() => validateUserPermission("create", "mailing_templates"))
const hasPermissionToUpdateMailTemplate = computed(() => validateUserPermission("update", "mailing_templates"))

let tabulatorMailTemplate: Tabulator | null;
const tableColumns: ColumnDefinition[] = [
    {
        title: "Template Name",
        field: "name",
        width: "30vh",
        headerSort: false
    },
    {
        title: "Response Days",
        field: "response_days",
        width: "20vh",
        headerSort: false
    },
    {
        title: "Created On",
        field: "created_at",
        width: "20vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getValue()
            return (data) ? dayjs(data).format("M/D/YYYY") : ""
        }
    },
    {
        title: "Updated On",
        field: "updated_at",
        width: "20vh",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getValue()
            return (data) ? dayjs(data).format("M/D/YYYY") : ""
        }
    },
    {
        title: "&nbsp;",
        field: "updated_at",
        width: "10vh",
        headerSort: false,
        visible: hasPermissionToUpdateMailTemplate.value,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as MailTemplates
            const edit = {
                id: data.id,
                name: data.name,
                response_days: data.response_days,
            }

            const editButton = document.createElement("a")
            editButton.innerHTML = "<span class='fa fa-edit' aria-hidden='true'></span> Edit"
            editButton.classList.add("edit-btn")
            editButton.href = "javascript:;"
            editButton.addEventListener("click", () => editMailTemplate.value = edit)
            return editButton;
        },
    }
]

const fetchMailTemplatesList = async () => {
    editMailTemplate.value = null // Close dialog

    const tabulatorURL = `${baseURL}/mailing/templates-list/`

    if (tabulatorMailTemplate) {
        tabulatorMailTemplate.setData(tabulatorURL)
        tabulatorMailTemplate.clearAlert()
        return
    }

    tabulatorMailTemplate = await createAjaxTabulator("#config-mail-template-list", {
        columns: tableColumns,
        initialSort: [
            { column: "name", dir: "asc" },
        ],
        pagination: true,
    })

    tabulatorMailTemplate.on("tableBuilt", () => {
        if (!tabulatorMailTemplate) return
        tabulatorMailTemplate.setData(tabulatorURL)
    })
}

onMounted(() => fetchMailTemplatesList())
onUnmounted(() => destroyTabulator(tabulatorMailTemplate))
</script>

<style>
@import "@/assets/admin-page.css";
</style>