<template>
    <div>
        <label class="config-header">Onboarding</label>
        <div class="row">
            <div class="col-3">
                <div class="d-flex">
                    <button class="searchButton cbFormTextField-sm mt-2" type="button"
                            @click="configsList">
                        Configs
                    </button>
                    <button class="searchButton cbFormTextField-sm mt-2" type="button"
                            @click="createNewConfig">
                        New Config
                    </button>
                </div>
                <div class="d-flex">
                    <button class="searchButton cbFormTextField-sm mt-2" type="button"
                            @click="ingestionsList">
                        Ingestions
                    </button>
                    <button class="searchButton cbFormTextField-sm mt-2" type="button"
                            @click="createNewIngestion">
                        New Ingestion
                    </button>
                </div>
                <div class="d-flex">
                    <button class="searchButton cbFormTextField-sm mt-2" type="button"
                            @click="dataRequestsList">
                        Data Requests
                    </button>
                    <button class="searchButton cbFormTextField-sm mt-2" type="button"
                            @click="createNewDataRequest">
                        New Data Request
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
r
<script setup lang="ts">
import router from "@/router"

const configsList = () => {
    router.push({ name: "onboardingConfigs" })
}

const createNewConfig = () => {
    router.push({name: 'onboardingConfig', params: { id: 'new' }})
}

const ingestionsList = () => {
    router.push({ name: "ingestions" })
}

const createNewIngestion = () => {
    router.push({ name: "ingestion", params: { id: 'new' }})
}

const dataRequestsList = () => {
    router.push({ name: "dataRequests" })
}

const createNewDataRequest = () => {
    router.push({ name: "dataRequest", params: { id: 'new' }})
}
</script>
