<template>
    <div class="container ir-card">
        <ReportPageHeader :logo-path="'/images/tr_icon.png'" :header-text="'Investigation Report'" />
        <div v-if="!loadingState">
            <div v-if="hasAccess">
                <h2 class="info-header d-block">
                    Investigation Summary
                    <a href="#" @click="showSummaryEditForm = true" class="float-end text-white"
                        v-if="(!showSummaryEditForm && showEditFeature)" id="edit-summary">
                        <span class="fa fa-edit" aria-hidden="true"></span>
                        Edit
                    </a>
                </h2>
                <InvestigationReportDetail v-if="!showSummaryEditForm" :data="investigationSummary"
                    id="investigation-summary" />
                <InvestigationReportSummaryEdit v-else @form-updated="formUpdateHandler()"
                    @cancel-edit="() => showSummaryEditForm = false" :data="investigationSummaryEdit"
                    :prettyNames="IRPrettyNames" />
                <h2 class="info-header">Taxroll Details</h2>
                <InvestigationReportDetail :data="taxrollDetails" id="investigation-taxroll-detail" />
                <h2 class="info-header">Investigation Summary</h2>
                <InvestigationReportDetail :data="investigationDetails" id="investigation-summary-2" />
                <h2 class="info-header">Document Evidence</h2>
                <div id="document-list" class="documentListTable"></div>
            </div>
            <div v-else class="text-center">
                {{ pageErrorMessage }}
            </div>
        </div>
        <div v-else>
            <loading-icon />
        </div>
    </div>

    <ModalDialog v-if="showDeleteDocumentDialog" title="Delete Document" :close="() => { }">
        Are you sure you want to delete this document?
        <br>
        <br>
        This action cannot be undone.

        <template #footer>
            <loading-icon v-if="deleteDocumentLoading" />
            <button @click="deleteDocument()" :disabled="deleteDocumentLoading"
                class="searchButton removeButton">Confirm</button>
            <button @click="closeDeleteDocumentDialog()" id="close-modal"
                class="searchButton clearButton">Close</button>
        </template>
    </ModalDialog>
    <AppMonitor />
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { useRoute } from "vue-router"
import type { AxiosError } from "axios"
import { useProfile } from "@/stores/profile";
import { useAPI } from "@/helpers/services/api";
import { toast } from "@/helpers/toast";
import { ref, onMounted, computed, watch } from "vue"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import type { InfoProperty, InvestigationReportSummary, CandidateDocumentList } from "@/helpers/interface/candidates";
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { getApiErrorMessage, formatProperties, ucwords } from "@/helpers/common"
import InvestigationReportDetail from "@/components/InvestigationReport/InvestigationReportDetail.vue";
import InvestigationReportSummaryEdit from "@/components/InvestigationReport/InvestigationReportSummaryEdit.vue";
import { TabulatorFull as Tabulator } from 'tabulator-tables'
import type {
    ColumnDefinition,
    CellComponent,
} from "tabulator-tables";
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import ReportPageHeader from "@/components/Shared/ReportPageHeader.vue";

const api = useAPI()
const route = useRoute()
const storeProfile = useProfile()
const hasAccess = ref<boolean>(false)
const loadingState = ref<boolean>(false)
const showSummaryEditForm = ref<boolean>(false)
const showEditFeature = ref<boolean>(false)
const pageErrorMessage = ref<string>("")

const storeCandidateDetails = useCandidateDetailsStore()
const investigationSummary = ref<InfoProperty[]>([])
const investigationSummaryEdit = ref<Partial<InvestigationReportSummary>>({})
const taxrollDetails = computed<InfoProperty[]>(() => {
    const situsInfoPrettyNames = {
        "parcel_num": "Parcel Number",
        "owner_name": "Owner/Applicant Name(s)",
        "situs_full_taxroll": "Situs",
        "mail_full_taxroll": "Mail",
        "exmpt_description": "Exemption(s)",
        "legal_description": "Legal",
    }
    return formatProperties(storeCandidateDetails.situsInfo, situsInfoPrettyNames) as InfoProperty[]
})

const IRPrettyNames: Partial<InvestigationReportSummary> = {
    "summary": "Recommendation Summary",
    "first_unqualified_year": "First Unqualified Year",
    "primary_violation_reason": "Primary Violation Reason",
    "owner_evidence": "Owner/Applicant Evidence",
    "situs_evidence": "Situs Residents Evidence	",
    "user_id": "TrueReviewer",
    "released_on": "Report Created On"
}
const investigationDetails = ref<InfoProperty[]>([])
const documentList = computed(() => (storeCandidateDetails.getDocumentList))
const showDeleteDocumentDialog = ref<boolean>(false)
const selectedDeleteDocument = ref<string>("")
const deleteDocumentLoading = ref(false)

const tru_id = route?.params?.tru_id as string
const ir_id = ref(route?.params?.ir_id as string)
const customer_id = computed(() => (storeProfile.getCustomerAccess?.customer_id))
const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error, { "featureName": "Investigation Report" })
    pageErrorMessage.value = message
    toast.error(message)
}

let documentListTabulator: Tabulator | null;

const fetchIRDetails = async (customer_id: string | undefined, tru_id: string, ir_id: string, errorHandler: Function) => {
    let url = `/taxroll/investigation_reports?customer_id=${customer_id}&tru_id=${tru_id}`
    url += ir_id ? `&ir_id=${ir_id}` : "&sort_by=created&order_by=asc&count=1"

    await api.get(url)
        .then(response => {
            if (response.data.length) {
                const data = response.data
                fetchIRDetailsSetup(data)
            }
            else pageErrorMessage.value = "Investigation Report not found"
        })
        .catch(error => errorHandler(error))
        .finally(() => loadingState.value = false)
}

const fetchIRDetailsSetup = (responseData: any) => {
    const data = responseData[0]
    hasAccess.value = true
    const IRPrettyNames = {
        "summary": "Recommendation Summary",
        "first_unqualified_year": "First Unqualified Year",
        "primary_violation_reason": "Primary Violation Reason",
        "owner_evidence": "Owner/Applicant Evidence",
        "situs_evidence": "Situs Residents Evidence	",
        "created_by": "TrueReviewer",
        "released_on": "Report Created On"
    }
    investigationSummary.value = formatProperties(data, IRPrettyNames, [], ["created_by", "released_on"]) as InfoProperty[]
    investigationDetails.value = formatProperties(data, IRPrettyNames, ["created_by", "released_on"]) as InfoProperty[]
    investigationSummaryEdit.value = data

    // Hide edit function if its already released
    showEditFeature.value = !data?.released

    // Format Values
    investigationDetails.value = investigationDetails.value.map(field => {
        if (field.key === IRPrettyNames['released_on'] && field.value !== null) {
            field.value = dayjs(field.value).format("M/DD/YYYY")
        }
        if (field.key === IRPrettyNames['created_by'] && field.value !== null) {
            field.value = ucwords(field.value)
        }
        return field
    })
}


const deleteDocument = async () => {
    deleteDocumentLoading.value = true
    await storeCandidateDetails.deleteDocument(selectedDeleteDocument.value)?.then(async () => {
        await storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
        toast.success("Document has been removed")
    })?.catch(error => loadFailureHandler(error))

    deleteDocumentLoading.value = false
    closeDeleteDocumentDialog()
}

const formUpdateHandler = async () => {
    showSummaryEditForm.value = false
    loadingState.value = true
    await fetchIRDetails(customer_id.value, tru_id, ir_id.value, loadFailureHandler)
    storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
}

const showDeleteDocumentDialogConfirmation = (documentId: string) => {
    showDeleteDocumentDialog.value = true
    selectedDeleteDocument.value = documentId
}

const closeDeleteDocumentDialog = () => {
    showDeleteDocumentDialog.value = false
    selectedDeleteDocument.value = ""
}

const documentListColumns = ref<ColumnDefinition[]>([
    { title: "Document List", field: "document_name", visible: true, width: "30%" },
    {
        title: "Link",
        field: "link",
        width: "15%",
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            if (data.link) return `<a href="${data.link}" target="_blank">Link</a>`
            return "-"
        }
    },
    {
        title: "Note",
        field: "notes",
        width: "20%",
    },
    {
        title: "File",
        field: "created_at",
        width: "15%",
        headerSort: false,
        formatter: function (cell: CellComponent) {
            const data = cell.getData() as CandidateDocumentList
            if (data.download_url) return `<a href="${data.download_url}" target="_blank">Download</a>`
            return "-"
        }
    },
    {
        title: "",
        field: "id",
        visible: true,
        headerSort: false,
        width: "20%",
        formatter: function (cell: CellComponent) {
            const id = cell.getValue();
            const deleteButton = document.createElement("a")
            deleteButton.href = "javascript:;"
            deleteButton.classList.add("text-dark")
            deleteButton.innerHTML = "<span class='fa fa-trash' aria-hidden='true'></span> Delete"
            deleteButton.addEventListener("click", () => showDeleteDocumentDialogConfirmation(id))
            return deleteButton;
        },
    },
])


onMounted(async () => {
    loadingState.value = true
    await storeCandidateDetails.fetchSitusInfo(tru_id, loadFailureHandler)
    await storeProfile.fetchCustomerAccess()
})

watch(() => customer_id.value, async (customer_id: string | undefined) => {
    if (!customer_id) return
    await fetchIRDetails(customer_id, tru_id, ir_id.value, loadFailureHandler)
    storeCandidateDetails.fetchDocumentList(tru_id, loadFailureHandler)
})

watch(() => documentList.value, async (list: CandidateDocumentList[]) => {
    if (!documentListTabulator) {
        buildTabulator(list)
    }

})

const buildTabulator = (list: CandidateDocumentList[]) => {
    documentListTabulator = new Tabulator("#document-list", {
        data: list,
        layout: "fitColumns",
        placeholder: "No records found.",
        reactiveData: true,
        responsiveLayout: true,
        columns: documentListColumns.value,
    });
}

</script>

<style scoped>
@import "@/assets/ir-page.css";
</style>