<template>
	<div id="notes-list"></div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import type { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type { FlagsAPIResponse } from "@/helpers/interface/candidates"
import type { ColumnDefinition, CellComponent, } from "tabulator-tables";
import { createTabulator, destroyTabulator } from "@/helpers/true-tabulator"

const store = useCandidateDetailsStore();
const notes = computed((): FlagsAPIResponse[] => store.getNotes)
let tabulator: Tabulator | null
const tableColumns_notes = ref<ColumnDefinition[]>([
	{ title: "Type", field: "name", visible: true, width: "20%", formatter: "textarea", headerSort: true },
	{
		title: "Additional Details",
		field: "display_html",
		width: "60%",
		visible: true,
		headerSort: true,
		formatter: function (cell: CellComponent) {
			const data = cell.getData() as FlagsAPIResponse;
			const display_html = data.display_html || ""
			return display_html.trim()
		}
	},
	{
		title: "Date",
		field: "event_date",
		visible: true,
		width: "20%",
		headerSort: true,
		formatter: function (cell: CellComponent) {
			return dayjs(cell.getValue()).format("M/D/YYYY")
		}
	}
])

const loadTabulator = async (data: FlagsAPIResponse[] | undefined) => {
	tabulator = await createTabulator("#notes-list", {
		data: data,
		columns: tableColumns_notes.value,
		initialSort: [
			{ column: "event_date", dir: "desc" },
		],
		pagination: false,
		placeholder: "No records found.",
	})
}

onMounted(() => loadTabulator(notes.value))
onUnmounted(() => destroyTabulator(tabulator))
watch(() => notes.value, async (data) => {
	await loadTabulator(data)
});
</script>

<style>
@import "@/assets/candidate-details.css";
</style>