<template>
    <div>
        <TheHeader />
        <div v-if="betaFeaturesEnabled" class="sidebar-content">
            <Card>
                <template #content>
                    <TaxRollExplorerListNew />
                </template>
            </Card>
        </div>
        <div v-else>
            <!-- Filtering -->
            <form @submit.prevent="submitFilter" @keydown.enter.prevent="submitFilter">
                <table class="candidates-list-filtering overflow-auto">
                    <tr class="cbFormTableRow p-2">
                        <td class="labelCell formLabel">
                            <label for="Value1_1">
                                PIN
                            </label>
                        </td>
                        <td class="cbFormFieldCell">
                            <input type="text" maxlength="255" id="pin" v-model="taxRollListFilterForm.pin"
                                placeholder="no dashes" class="cbFormTextField" size="25">
                        </td>
                        <td class="labelCell formLabel">
                            <label for="Value3_1">
                                Situs Address
                                <abbr class="filter-tooltip" title="* is a wildcard">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </label>
                        </td>
                        <td class="cbFormFieldCell">
                            <input type="text" maxlength="255" id="situs" v-model="taxRollListFilterForm.situs"
                                class="cbFormTextField" size="25">
                        </td>
                        <td class="labelCell formLabel">
                            <label for="Value4_1">
                                Owner Name(s)
                                <abbr class="filter-tooltip" title="* is a wildcard">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </label>
                        </td>
                        <td class="cbFormFieldCell">
                            <input type="text" maxlength="255" id="owner" v-model="taxRollListFilterForm.owner"
                                class="cbFormTextField" size="25">
                        </td>
                        <td class="labelCell formLabel">
                            <label for="Value4_1">
                                Exemption(s)
                                <abbr class="filter-tooltip" title='"contains search"'>
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </label>
                        </td>
                        <td class="cbFormFieldCell">
                            <input type="text" maxlength="255" id="exemptions"
                                v-model="taxRollListFilterForm.exemptions" class="cbFormTextField" size="25">
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow mt-2" style="display: inline-block;">
                        <td class="labelCell formLabel">
                            <button type="button" @click="submitFilter" class="searchButton">Search</button>
                            <button type="button" @click="showClearSearchConfirmation = true" class="clearButton">Clear
                                Search</button>
                        </td>
                    </tr>
                </table>
            </form>
            <!-- List -->
            <div class="taxroll-list">
                <TaxRollList :requestHeaders="sendRequestHeaders" />
            </div>

            <teleport to="body">
                <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Clear Search"
                    :close="() => showClearSearchConfirmation = false">
                    Are you sure you want to clear the search?
                    <br>
                    <br>
                    This action cannot be undone, and all current search filters
                    and input will be removed.

                    <template #footer>
                        <button @click="clearSearchField" class="btn confirmClearSearch">Confirm</button>
                        <button style="border: 1px solid #ccc" @click="showClearSearchConfirmation = false"
                            class="btn btn-default">Close</button>
                    </template>
                </ModalDialog>
            </teleport>
        </div>
    </div>
    <AppMonitor />
</template>

<script setup lang="ts">
import TheHeader from "@/components/TheHeader.vue"
import AppMonitor from "@/components/AppMonitor.vue"
import TaxRollList from "@/components/TaxRollExplorer/TaxRollExplorerList.vue"
import type { TaxRollListFilterForm, CandidateFilter } from "@/helpers/interface/candidates"
import { prepareFilterFieldObject } from "@/helpers/common"
import ModalDialog from "@/components/Shared/ModalDialog.vue";
import { computed, ref } from "vue"
import Card from 'primevue/card';
import TaxRollExplorerListNew from "@/components/TaxRollExplorer/TaxRollExplorerListNew.vue";
import { useProfile } from "@/stores/profile"

const storeProfile = useProfile()
const betaFeaturesEnabled = computed(() => storeProfile.enableBetaFeatures)

let sendRequestHeaders = ref<CandidateFilter>({
    // Default values
    filter: []
})

const taxRollListFilterForm = ref<TaxRollListFilterForm>({
    pin: "",
    owner: "",
    situs: "",
    exemptions: "",
})
const showClearSearchConfirmation = ref<boolean>(false)

const submitFilter = () => {
    let filter = []
    if (taxRollListFilterForm.value.pin != "") filter.push(prepareFilterFieldObject("pin", "like", taxRollListFilterForm.value.pin))
    if (taxRollListFilterForm.value.situs != "") filter.push(prepareFilterFieldObject("situs", "like", taxRollListFilterForm.value.situs))
    if (taxRollListFilterForm.value.owner != "") filter.push(prepareFilterFieldObject("owner", "like", taxRollListFilterForm.value.owner))
    if (taxRollListFilterForm.value.exemptions != "") filter.push(prepareFilterFieldObject("exemptions", "like", taxRollListFilterForm.value.exemptions))

    sendRequestHeaders.value.filter = filter
}

const clearSearchField = () => {
    showClearSearchConfirmation.value = false
    taxRollListFilterForm.value = {
        pin: "",
        owner: "",
        situs: "",
        exemptions: ""
    }
    submitFilter()
}
</script>

<style>
@import "@/assets/candidates-list.css";
</style>
