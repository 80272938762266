<template>
    <div id="config-user-invitation-container">
        <form @submit.prevent="submitHandler" class="my-3">
            <div class="row">
                <div class="col-sm-12">
                    <label for="config-user-invite-email">
                        Name <span class="required">*</span>
                    </label>
                    <input id="config-user-invite-name" type="name" class="cbFormTextField cbFormTextField-xl"
                        maxlength="255" required title="Name" ref="nameInput" />
                    <label for="config-user-invite-email" class="mt-2">
                        Email address <span class="required">*</span>
                    </label>
                    <input id="config-user-invite-email" type="email" class="cbFormTextField cbFormTextField-xl"
                        maxlength="255" required title="A valid email address" spellcheck="false" autocomplete="off"
                        autocorrect="false" autocapitalize="off" ref="emailInput" />
                    <label for="config-user-invite-email" class="mt-2">
                        Assign a role <span class="required">*</span>
                    </label>
                    <select ref="roleInput" id="roles-select" class="cbFormTextField cbFormTextField-xl" required>
                        <option value="" selected>-- Select --</option>
                        <option v-for=" role  in  allAvailableRoles " :value="role.id" :key="role.id">
                            {{ role.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-12 mt-2 d-flex">
                    <button type="submit" class="searchButton" :disabled="formSubmitting">Send Invitation</button>
                    <LoadingIcon v-if="formSubmitting" />
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api"
import { getApiErrorMessage } from "@/helpers/common"
import { isValidEmail } from "@/helpers/regex"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import { useAdminDetails } from "@/stores/adminDetails";
import type { AxiosError } from "axios"
import type { UserRoles } from "@/helpers/interface/admin-page"

const api = useAPI()
const storeAdminDetails = useAdminDetails()

const emits = defineEmits(["userInvitationSent"])
const nameInput = ref<HTMLInputElement | null>(null)
const emailInput = ref<HTMLInputElement | null>(null)
const roleInput = ref<HTMLInputElement | null>(null)
const formSubmitting = ref(false)
const allAvailableRoles = computed((): UserRoles[] => storeAdminDetails.getAllAvailableRoles)

const submitHandler = async () => {
    const name = nameInput.value as HTMLInputElement
    const email = emailInput.value as HTMLInputElement
    const role = roleInput.value as HTMLInputElement

    try {

        formSubmitting.value = true
        const data = {
            "name": name.value,
            "email": email.value,
            "role": role.value,
        }

        if (!isValidEmail(email.value)) {
            toast.error(`${email.value} is not a valid email address.`)
            return
        }

        await api.post("users/auth0/invite", data)
        toast.success(`An invitation will be sent to ${email.value}`)
        name.value = ""
        email.value = ""
        role.value = ""
        emits("userInvitationSent")

    } catch (error: any) {
        toast.error(getApiErrorMessage(error))

    } finally {
        formSubmitting.value = false
        name.focus()
    }
}

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error, { "featureName": "Customer List" })
    toast.error(message)
}

onMounted(async () => {
    nameInput.value?.focus()
    await storeAdminDetails.fetchAllAvailableRoles(loadFailureHandler)
})

</script>