import { getCookie } from "@/helpers/common"

const productFruitsScript = (w: any, d: any) => {
    const workspaceID = import.meta.env.VITE_PRODUCTFRUITS_WORKSPACE
    if (!workspaceID) {
        console.debug("Product fruits workspace ID not set")
        return
    }
    w.$productFruits = w.$productFruits || [];
    w.productFruits = w.productFruits || {};
    w.productFruits.scrV = '2';
    const r = d.createElement('script');
    r.async = true;
    r.src = "https://app.productfruits.com/static/script.js"

    const someScriptTag = d.getElementsByTagName('head')[0] as HTMLScriptElement | null
    if (someScriptTag && someScriptTag.parentNode) {
        someScriptTag.appendChild(r);
        console.log("pf_script_loaded")
    }

    w.$productFruits.push(['init', workspaceID, 'en', {
        username: getCookie('productFruitsOfLoom') || ""
    }]);
}

const happyFoxScript = (w: any, d: any) => {
    const happyfoxToken = import.meta.env.VITE_HAPPYFOX_EMBED_TOKEN as string
    if (!happyfoxToken) {
        console.debug("HappyFox embed token not set")
        return
    }
    w.HFCHAT_CONFIG = {
        EMBED_TOKEN: happyfoxToken,
        ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
        options: { showBadgeOnLoad: false }
    };

    w.HFCHAT_CONFIG.onload = function () {
        const HappyFoxChat = this
        HappyFoxChat.pageLoaded(function (err: any) {
            if (err) {
                console.error('Failed to capture page navigation. Error:', err)
                return
            }
            w.addEventListener('productfruits_button_ext_widget_init', function () {
                HappyFoxChat.showWidget()
                HappyFoxChat.expandChatbox()
            })

            HappyFoxChat.on('collapse:chatbox', function () {
                HappyFoxChat.hideWidget()
                w.productFruits.api.button.showButton()
            })
            console.log("hfchat_script_loaded")

            // Product fruits script
            productFruitsScript(w, d)
        })
    }

    const scriptTag = d.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.async = true
    scriptTag.src = w.HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js'
    const someScriptTag = d.getElementsByTagName('script')[0] as HTMLScriptElement | null

    if (someScriptTag && someScriptTag.parentNode) {
        someScriptTag.parentNode.insertBefore(scriptTag, someScriptTag)
    }
}


const PFDisplayAnnouncementBanner = (w: any, d: any) => {
    // Display PF announcements/banners
    w.productFruits.api.announcementsV2.listen('banner-spacing', (spacingInfo: any) => {
        /*
            The spacingInfo variable contains two props:
            * desiredMargin - a number of pixels
            * place - will be "top" or "bottom" depending on the banner announcement display type
        */

        // This is for custom placing of the top banner
        // it will be useful in the future ui/ux enhancement
        const el = d.getElementById('app');
        el.style.top = spacingInfo.desiredMargin + 'px';
    })
}

const PFNotifications = async (w: any, d: any) => {
    // Adding the notification Scripts for the icon
    let pfNotifLauncher = d.getElementById('pfNotifications') as HTMLAnchorElement;
    if (pfNotifLauncher != null) //if it exists
    {
        if (!localStorage.getItem("newsfeed-unread-count")) localStorage.setItem("newsfeed-unread-count", (0).toString())

        // Add the unread count number to the element
        w.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data: any) => {
            localStorage.setItem("newsfeed-unread-count", data.count.toString())
            PFUpdateNotificationCount(pfNotifLauncher, d)
        })

        PFUpdateNotificationCount(pfNotifLauncher, d)

        // Attach the product fruits functionality to the bell
        w.productFruits.api.announcementsV2.attachNewsWidgetToElement(pfNotifLauncher);
        pfNotifLauncher.style.display = "inline"
    }
}

const PFUpdateNotificationCount = (pfNotifLauncher: HTMLAnchorElement, d: any) => {
    let unreadCount = localStorage.getItem("newsfeed-unread-count") || "0"

    if (parseInt(unreadCount) > 0) // If unread is greater than 0 then there are new messages, display badge and color
    {
        pfNotifLauncher.classList.add('unread');
        d.getElementById('pfNotificationsBadge').textContent = unreadCount;
        pfNotifLauncher.title = `${unreadCount} Unread Messages`;
    } else { // Remove badge and grey bell when no more unread messages
        pfNotifLauncher.classList.remove('unread');
        pfNotifLauncher.title = "No Unread Messages"
    }
}

export const PFHFConfig = () => {
    const w = window as any
    const d = document as any

    // HappyFox script
    happyFoxScript(w, d)

    // ProductFruits announcement/banner
    productFruitsScript(w, d)

    const initializeProductFruits = () => {
        PFDisplayAnnouncementBanner(w, d);
        PFNotifications(w, d);
    };

    // Event listener for location change
    const locationChangeHandler = () => {
        initializeProductFruits();
    };

    // Event listener for ProductFruits ready
    const productFruitsReadyHandler = () => {
        initializeProductFruits();
    };

    // Add event listeners
    w.addEventListener('pfruits_locationchange', locationChangeHandler);
    w.addEventListener('productfruits_ready', productFruitsReadyHandler);
}
