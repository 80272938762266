<template>
    <div ref="taxrollDataView" id="taxrollDataView">
        <div class="row" id="taxroll-list-table-header-section">
            <div class="col-2 offset-10">
                <div class="float-end">
                    <div v-if="!loading" ref="overlayDiv">
                        <span class="me-2">
                            <a href="javascript:;" v-if="sortOrder === 'desc'" @click="changeSortOrder('asc')"
                                id="sort-asc-button" title="Sort Oldest First">
                                <span class="fa fa-sort-amount-down-alt"></span>
                                Sort
                            </a>
                            <a href="javascript:;" v-else @click="changeSortOrder('desc')" id="sort-desc-button"
                                title="Sort Newest First">
                                <span class="fa fa-sort-amount-up"></span>
                                Sort
                            </a>
                        </span>
                    </div>
                    <div v-else>
                        <Skeleton width="4rem" height="2rem" class="mb-2"></Skeleton>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading">
            <div v-for="i in 5" :key="i" class="row candidate-list-row-card">
                <div class="col align-content-center">
                    <div class="p-4">
                        <Skeleton width="11rem" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="15rem" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="6rem" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col align-content-center">
                    <Skeleton width="5rem" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="14rem" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="10rem" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col align-content-center">
                    <Skeleton width="8rem" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="10rem" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="8rem" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col align-content-center">
                    <div class="p-3">
                        <Skeleton width="10rem" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="11rem" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="13rem" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="8rem" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="10rem" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
            </div>
        </div>
        <DataView :class="{ 'd-none': loading }" :value="data" :paginator="true" dataKey="application_id" :first="first"
            :rows="rows" filterDisplay="row" :loading="loading" :id="'DataView'" lazy :totalRecords="totalRecords"
            :rowsPerPageOptions="[25, 50, 100, 200, 500]"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink  PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" @page="onPage($event)">
            <template #paginatorstart>
            </template>
            <template #paginatorend>
            </template>
            <template #header>

            </template>
            <template #empty>
                <div class="p-2 text-center">
                    No results found.
                </div>
            </template>

            <template #list="slotProps">
                <div :style="`height: ${scrollHeight}px; overflow-y: auto;`">
                    <div v-for="(item, index) in (slotProps.items as TaxRollList[])" :key="index"
                        class="row dataview-list-row-card">
                        <div class="col align-content-center clipboard-copy-wrapper">
                            <div class="p-3">
                                <div class="mb-2">
                                    <span>
                                        PIN: {{ item.parcel_num }}
                                    </span>
                                    <span class="clipboard-copy-target" :id="item?.tru_id || ''"
                                        :data-copy-text="item?.parcel_num"></span>
                                </div>
                                <div class="mb-2">
                                    Situs: {{ item.situs }}
                                </div>
                                <div class="mb-2">
                                    Country Lookup: <a :href="item.county_lookup_url" target="_blank">Link</a>
                                </div>
                            </div>
                        </div>
                        <div class="col align-content-center">
                            <div class="p-3">
                                <div class="mb-2">
                                    Owner: {{ item.owner }}
                                </div>
                                <div class="mb-2">
                                    Mailing: {{ item.mail }}
                                </div>
                                <div class="mb-2">
                                    Exemption(s): {{ item.exemptions }}
                                </div>
                            </div>
                        </div>
                        <div class="col align-content-center">
                            <div class="p-3">
                                <div class="mb-2">
                                    Market Value: {{ item.market_value ? item.market_value : "-" }}
                                </div>
                                <div class="mb-2">
                                    Assessed Value: {{ item.assessed_value ? item.assessed_value : "-" }}
                                </div>
                                <div class="mb-2">
                                    Taxable Value: {{ item.taxable_value ? item.taxable_value : "-" }}
                                </div>
                            </div>
                        </div>
                        <div class="col align-content-center">
                            <div class="p-3">
                                <div class="mb-2">
                                    Candidate Queue: {{ ucfirst(item.queue) }}
                                </div>
                                <div class="mb-2">
                                    Candidate Found: {{ formatDate(item.identified_on) }}
                                </div>
                                <div class="mb-2">
                                    Candidate Updated: {{ formatDate(item.updated_on) }}
                                </div>
                                <div class="mb-2">
                                    Parcel Created: {{ formatDate(item.parcel_created) }}
                                </div>
                                <div class="mb-2">
                                    Parcel Updated: {{ formatDate(item.parcel_updated) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import {
    getApiErrorMessage,
    calculateScrollHeight,
    buildCopyToClipboardButton,
    ucfirst,
    formatDate
} from "@/helpers/common"
import type { ApplicationVetter } from "@/helpers/interface/appvet"
import type { DataTableEvent } from "@/helpers/interface/general"
import DataView from 'primevue/dataview';
import Skeleton from 'primevue/skeleton';
import type { TaxRollList } from "@/helpers/interface/candidates";

const api = useAPI()
const loading = ref(true)
const first = ref(0)
const rows = ref(25)
const totalRecords = ref(0)
const data = ref<ApplicationVetter[]>([])
const dataParams = ref<DataTableEvent | null>(null)
const currentPage = ref(1)

// For Dynamic ScrollHeight Calculation
const taxrollDataView = ref<HTMLElement | null>(null)
const scrollHeight = ref<number>(0)

const sortOrder = ref<"desc" | "asc">("desc")
const changeSortOrder = (sort: "desc" | "asc") => {
    sortOrder.value = sort
    loadData()
}

const configParams = (event: any) => {
    rows.value = event?.rows
    return {
        "filter": [],
        "page": event?.page,
        "page_size": event?.rows,
        "sort": sortOrder.value
    }
}

const loadData = async (event: any = null) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }
    let payload = configParams(dataParams.value)


    try {
        const response = await api.post(`/taxroll/`, payload)
        data.value = response.data?.data || []
        totalRecords.value = response.data?.total || response.data?.last_page || 0

        // Re-align paginator bottom elements
        realignDataTableBottomElements(`#DataView`)

        // 
        let applicationVetterDiv = document.querySelector("#sharedSideNav .sidebar-content") as HTMLDivElement
        if (applicationVetterDiv) {
            applicationVetterDiv.style.marginBottom = totalRecords.value === 0 ? "60px" : "0px"
        }
    }
    catch (error: any) {
        data.value = []
        toast.error(getApiErrorMessage(error))
    }
    loading.value = false

    appendCopyButton()
}

const realignDataTableBottomElements = (datatableID: string) => {
    const paginatorCurrent = document.querySelector(`${datatableID} .p-paginator-current`) as HTMLElement
    const paginatorLeftContent = document.querySelector(`${datatableID} .p-paginator-left-content`) as HTMLElement
    if (paginatorLeftContent) {
        paginatorLeftContent.append(paginatorCurrent)
    }

    const paginatorDropdown = document.querySelector(`${datatableID} .p-paginator-bottom .p-dropdown`) as HTMLElement
    const paginatorRightContent = document.querySelector(`${datatableID} .p-paginator-right-content`) as HTMLElement
    if (paginatorRightContent) {
        paginatorRightContent.append(paginatorDropdown)
    }
}

const onPage = async (event: DataTableEvent) => {
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    await loadData(event)
}

// Filter overlay (popup)
const overlayStyle = ref({})
const overlayDiv = ref<HTMLElement | null>(null)
const filterButton = ref<HTMLElement | null>(null)

const setFilterDivPosition = () => {
    if (filterButton.value && taxrollDataView.value) {
        const filterButtonRect = filterButton.value.getBoundingClientRect()
        const taxrollDataViewRect = taxrollDataView.value.getBoundingClientRect()
        overlayStyle.value = {
            position: 'absolute',
            top: `${filterButtonRect.bottom + 15}px`,
            right: `${taxrollDataViewRect.right - filterButtonRect.right + 10}px`
        }
    }
}

const setListScrollHeight = () => {
    scrollHeight.value = calculateScrollHeight(taxrollDataView.value)
    setFilterDivPosition()
}

const appendCopyButton = () => setTimeout(() => buildCopyToClipboardButton(), 1000)
watch(taxrollDataView, () => {
    // when list initially loads, this will fire and allow 
    // setting it's ideal height to the viewport
    setListScrollHeight()
})

onMounted(async () => {
    window.addEventListener("resize", setListScrollHeight)
    dataParams.value = {
        first: 0,
        rows: 25,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: []
    }
    await loadData()
})

onUnmounted(async () => {
    window.removeEventListener("resize", setListScrollHeight)
})

</script>

<style>
@import "@/assets/appvet.css";
</style>
