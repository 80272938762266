<template>
    <div class="actions-description">
        You may add flags manually to this Candidate using the form below.
    </div>
    <div class="actions-form">
        <form @submit.prevent="submitFlagForm()">
            <div class="row p-2">
                <div class="col-12">
                    Flag Type <span class="required">*</span>
                    <br>
                    <select class="cbFormTextField" id="flag_id" v-model="formFlags.flag_id" required>
                        <option value="" selected>-- Select --</option>
                        <option v-for="flag in flagNamesListDropdown" :value="flag.id" :key="flag.id">
                            {{ flag.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-12">
                    Flag Details
                    <br>
                    <textarea v-model="formFlags.description" id="description" class="textwrapper"
                        :maxlength="MAX_NOTE_LENGTH"></textarea>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-12">
                    Occurred On <span class="required">*</span>
                    <br>
                    <input type="date" v-model="formFlags.event_date" id="event_date" class="cbFormTextField" required>
                </div>
            </div>
            <div class="row pt-2">
                <div class="offset-4 col-6 d-flex">
                    <loading-icon v-if="formLoading || fetchFlagNamesLoading" />
                    <button class="searchButton" type="submit"
                        :disabled="formLoading || fetchFlagNamesLoading">Update</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import type { CandidateFormFlags } from "@/helpers/interface/candidates"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import { useAPI } from "@/helpers/services/api";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { MAX_NOTE_LENGTH, getApiErrorMessage } from "@/helpers/common";
import { useRoute } from "vue-router"
import { toast } from "@/helpers/toast";
import type { AxiosError } from "axios";

interface FlagItem {
    id: number,
    name: string
}

const route = useRoute()
const api = useAPI()
const storeCandidateDetails = useCandidateDetailsStore();
const tru_id = route?.params?.tru_id as string
const fetchFlagNamesLoading = ref(false)
const formLoading = ref(false)

const flagNamesListDropdown = ref<FlagItem[]>([])
const currentQueue = computed(() => (storeCandidateDetails.situsInfo.queue));

const formFlagsInitialState = {
    flag_id: "",
    description: "",
    event_date: ""
}
const formFlags = ref<CandidateFormFlags>({
    ...formFlagsInitialState
})



const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

watch(() => currentQueue.value, () => {
    fetchFlagNamesList()
});

let fetchFlagNamesList = async () => {
    fetchFlagNamesLoading.value = true;
    let items = [] as FlagItem[]
    try {
        const response = await api.get('/taxroll/flags');
        if (response.data) {
            items = response.data
        } 
    }
    finally {
        flagNamesListDropdown.value = items
        fetchFlagNamesLoading.value = false;
    }
}

let submitFlagForm = async () => {
    formLoading.value = true
    await api.post(`/taxroll/${tru_id}/flags`, formFlags.value)?.then(async () => {
        toast.success("New flag submitted")
        formFlags.value = { ...formFlagsInitialState }
        await storeCandidateDetails.fetchFlags(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
    })?.catch((error: AxiosError) => {
        toast.error(getApiErrorMessage(error))
    })?.finally(() => {
        formLoading.value = false;
    })
}
</script>

<style>
@import "@/assets/candidate-details.css";
</style>
