export const AdminConfig = [
    {
        "menu": "Workflow: Mail Module",
        "page": "workflow_mail_module",
        "requiredPermissions": [
            "read:postal_contact_workflows",
            "update:postal_contact_workflows",
            "create:postal_contact_workflows"
        ]
    },
    {
        "menu": "Config: Labels",
        "page": "config_labels",
        "requiredPermissions": [
            "create:labels"
        ]
    },
    {
        "menu": "Config: Mail Templates",
        "page": "config_mail_templates",
        "requiredPermissions": [
            "update:mailing_templates",
            "create:mailing_templates"
        ]
    },
    {
        "menu": "Config: Change Customer",
        "page": "config_change_customer",
        "requiredPermissions": [
            "read:customers"
        ]
    },
    {
        "menu": "Config: Users",
        "page": "config_users",
        "requiredPermissions": [
            "create:users",
            "update:users",
        ]
    },
    {
        "menu": "Config: Settings",
        "page": "config_settings",
        "requiredPermissions": [
            "read:internal_setting",
            "update:internal_settings",
            "read:customers",
        ]
    },
    {
        "menu": "Update: Assign Candidate",
        "page": "update_assign_candidate",
        "requiredPermissions": [
            "update:candidates"
        ]
    },
    {
        "menu": "Report: New Assessed Value Estimate",
        "page": "report_new_assessed_value",
        "requiredPermissions": [
            "read:reports_assessed_value"
        ]
    },
    {
        "menu": "Report: Download Unclaimed List",
        "page": "dl_unclaimed_list",
        "requiredPermissions": [
            "read:reports_unclaimed"
        ]
    },
    {
        "menu": "Unclaimed Exemption Data Request",
        "page": "unclaimed_exmpt_data_req",
        "requiredPermissions": []
    },
    {
        "menu": "Onboarding",
        "page": "onboarding",
        "requiredPermissions": [
            "read:ingestion_configs",
            "update:ingestion_configs",
        ]
    }
]

export const SettingsConfig = [
    {
        "menu": "Update: Archived Candidates",
        "page": "update_archived_candidates",
        "requiredPermissions": [
            "update:candidates_archived"
        ]
    },
    {
        "menu": "User Preferences",
        "page": "user_preference",
        "requiredPermissions": [
            "update:internal_settings"        
        ]
    },
]