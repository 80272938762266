import { defineStore } from "pinia";
import type { ComputedRef, Ref } from "vue";
import type { Label } from "@/helpers/interface/candidates";
import { toast } from "@/helpers/toast"
import type { ApplicationVetter } from "@/helpers/interface/appvet";
import { useAPI } from "@/helpers/services/api"
import { downloadCSV, getApiErrorMessage } from "@/helpers/common";
import type { Auth0User } from "@/helpers/interface/admin-page";
import type { Axios } from "axios";

interface BulkLabelAction {
    add: Ref<Label[]>;
    remove: Ref<Label[]>;
    optionalNote: Ref<string>;
    loading: Ref<boolean>;
    parentURL: String;
}

interface BulkAssign {
    sub: string | undefined;
    user: Ref<Auth0User | null>;
    optionalNote: Ref<string>;
    loading: Ref<boolean>;
    showAssignDialog?: ComputedRef<boolean>;
    showAssignToMeDialog?: ComputedRef<boolean>;
    showUnassignDialog?: ComputedRef<boolean>;
    parentURL: String;
}

export type BulkIRAction = "release" | "delete" | null
export interface BulkIR {
    action: Ref<BulkIRAction>;
    loading: Ref<boolean>;
    showReleaseIRDialog: Ref<boolean>;
    showDeleteIRDialog: Ref<boolean>;
    parentURL: String;
}

interface BulkUpdate {
    status: Ref<StatusCode | "">;
    optionalNote: Ref<string>;
    loading: Ref<boolean>;
    statusText: ComputedRef<string>;
    parentURL: String;
}

interface BulkNote {
    note: Ref<String>;
    loading: Ref<boolean>;
    showNoteDialog: Ref<boolean>;
    parentURL: String;
}

interface BulkExport {
    export: Ref<"csv" | "pdf">;
    loading: Ref<boolean>;
    showExportDialog: Ref<boolean>;
    parentURL: String;
}

export type StatusCode = "approved" | "denied" | "deferred"

export const performLabelUpdate = async (api: Axios, action: "add" | "remove", bulkLabel: BulkLabelAction, additionalParam: any) => {
    const labelIds = bulkLabel[action].value.map(label => label.id)
    if (labelIds.length === 0) {
        toast.error(`Please select a label to ${action}.`)
        return "error"
    }
    bulkLabel.loading.value = true
    const params = {
        label_ids: labelIds,
        note: bulkLabel.optionalNote.value,
        ...additionalParam
    }
    try {
        const url = action === "add" ? `${bulkLabel.parentURL}/labels` : `${bulkLabel.parentURL}/labels/delete`
        await api.post(url, params)
        toast.success("Your submission was successful")
        bulkLabel.loading.value = false
        return "success"
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
        return "error"
    }
}

export const performAssignAction = async (api: Axios, action: "assign" | "unassign" | "assigntome", bulkAssign: BulkAssign, additionalParam: any) => {
    if (!bulkAssign.user.value && action === "assign") {
        toast.error("Please select a user.")
        return "error"
    }
    bulkAssign.loading.value = true
    const params = {
        user_id: action === "assigntome" ? bulkAssign.sub : bulkAssign.user.value?.user_id,
        note: bulkAssign.optionalNote.value,
        ...additionalParam
    }
    try {
        const url = action === "unassign" ? `${bulkAssign.parentURL}/unassign` : `${bulkAssign.parentURL}/assign`
        await api.patch(url, params)
        toast.success("Your submission was successful")
        bulkAssign.loading.value = false
        return "success"
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
        return "error"
    }
}

export const performUpdateStatusAction = async (api: Axios, status: string, bulkUpdate: BulkUpdate, additionalParam: any) => {
    bulkUpdate.loading.value = true

    const params = {
        status: status,
        note: bulkUpdate.optionalNote.value,
        ...additionalParam
    }

    try {
        await api.post(`${bulkUpdate.parentURL}/status`, params)
        toast.success("Your submission was successful")
        bulkUpdate.loading.value = false
        return "success"
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
        return "error"
    }
}

export const performNoteAction = async (api: Axios, bulkNote: BulkNote, additionalParam: any) => {
    if (!bulkNote.note.value) {
        toast.error("Please enter a note.")
        return "error"
    }
    const params = {
        note: bulkNote.note.value,
        ...additionalParam
    }
    bulkNote.loading.value = true
    try {
        await api.post(`${bulkNote.parentURL}/note`, params)
        toast.success("Your submission was successful")
        bulkNote.loading.value = false
        return "success"
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
        return "error"
    }
}

export const performExportAction = async (api: Axios, queue: string[], bulkExport: BulkExport, additionalParam: any) => {
    const defaultSort = [{ field: "submitted_date", dir: "desc" }]
    const params = {
        sort: defaultSort,
        ...additionalParam
    }
    bulkExport.loading.value = true
    try {
        if (bulkExport.export.value === "csv") {
            const response = await api.post(`${bulkExport.parentURL}/export`, params)
            let fileName
            if (queue.includes('pending')) fileName = 'inbox'
            else if (queue.includes('deferred')) fileName = 'deferred'
            else if (queue.includes('approved') || queue.includes('denied')) fileName = 'archive'
            fileName = `${fileName}-applications-`
            fileName = fileName + (new Date()).toISOString().substring(0, 19)
            downloadCSV(response.data, fileName)
            toast.success("Your submission was successful")
        }

        if (bulkExport.export.value === "pdf") {
            const response = await api.post(`${bulkExport.parentURL}/application_zip/export`, params)
            toast.success(response.data.message)
        }

        bulkExport.loading.value = false
        return "success"
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
        return "error"
    }
}


export const performIRAction = async (api: Axios, bulkIR: BulkIR, additionalParam: any) => {
    const params = { ...additionalParam }
    bulkIR.loading.value = true
    const subURL = bulkIR.action.value === "release" ? "release_investigation_reports" : "investigation_reports/delete"
    try {
        await api.patch(`${bulkIR.parentURL}/${subURL}`, params)
        toast.success("Your submission was successful")
        bulkIR.loading.value = false
        return "success"
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
        return "error"
    }
}