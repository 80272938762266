<template>
    <div id="parties">
        <div v-if="!fetchPartiesLoading">
            <table class="table" v-if="parties?.length">
                <tbody v-for="party in parties" :key="party.email">
                    <tr class="applicant-name">
                        <td colspan="2">
                            {{ party.applicant_name }}
                        </td>
                    </tr>
                    <tr>
                        <td class="label">Birthdate</td>
                        <td class="p-1 align-top value">{{ formatDate(party?.dob) }}</td>
                    </tr>
                    <tr>
                        <td class="label">Identity Document</td>
                        <td class="p-1 align-top value">{{ party?.identity_document }}</td>
                    </tr>
                    <tr>
                        <td class="label">Document Number</td>
                        <td class="p-1 align-top value">{{ party?.document_number }}</td>
                    </tr>
                    <tr>
                        <td class="label">Email</td>
                        <td class="p-1 align-top value">{{ party?.email }}</td>
                    </tr>
                    <tr>
                        <td class="label">Phone</td>
                        <td class="p-1 align-top value">{{ party?.phone }}</td>
                    </tr>
                    <tr>
                        <td class="label">Ownership Interest</td>
                        <td class="p-1 align-top value">{{ party?.ownership_interest }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table" v-else>
                <tbody>
                    <tr>
                        <td class="text-center">No records to display</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <table class="table">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <Skeleton height="1.2rem" width="100%"></Skeleton>
                        </td>
                    </tr>
                    <tr v-for="i in 6" :key="i">
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="250px"></Skeleton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Parties } from "@/helpers/interface/appvet"
import type { AxiosError } from "axios"
import { onMounted, ref } from "vue";
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { getApiErrorMessage, formatDate } from "@/helpers/common";
import { toast } from "@/helpers/toast";

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})

const api = useAPI()
const fetchPartiesLoading = ref(true)
const parties = ref<Parties[] | null>(null)
const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}
const fetchParties = async () => {
    const tru_id = props.tru_id
    fetchPartiesLoading.value = true

    try {
        const { data } = await api.get(`applications/${tru_id}/parties`)
        parties.value = data
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }

    fetchPartiesLoading.value = false
}

onMounted(() => fetchParties())
</script>

<style scoped>
#parties .applicant-name {
    text-align: center;
    background-color: var(--main-green-color);
    color: #fff;
    text-transform: uppercase;
}
</style>