<template>
    <div ref="overlayDiv" class="d-inline-flex">
        <div v-if="!props.loading">
            <span class="me-2">
                <a href="javascript:;" v-if="props.sortOrder === 'desc'" @click="changeSortOrder()" id="sort-asc-button"
                    title="Sort Oldest First">
                    <span class="fa fa-sort-amount-down-alt"></span>
                    Sort
                </a>
                <a href="javascript:;" v-else @click="changeSortOrder()" id="sort-desc-button"
                    title="Sort Newest First">
                    <span class="fa fa-sort-amount-up"></span>
                    Sort
                </a>
            </span>
            <span v-if="props.filterConfig">
                <a href="javascript:;" @click="toggleOverlay" id="filter-button" ref="filterButton">
                    <span class="fa fa-filter"></span>
                    Filter
                    <span v-if="props.filterConfig.currentFilteredFields > 0"> ({{
                        props.filterConfig.currentFilteredFields }})</span>
                </a>
                <PopoverOverlay :isFilterPopoverVisible="isFilterPopoverVisible" :style="overlayStyle">
                    <template #content>
                        <FilterComponent :initialFilters="props.filterConfig.initialFilters"
                            @submitFilters="handleSubmitFilters" @updateFilters="handleUpdateFilters"
                            @isDirty="onFiltersDirtyChanged" :filterSchema="props.filterConfig.filterSchema"
                            :operatorSubstitutions="props.filterConfig.operatorSubstitutions"
                            :operatorExclusions="props.filterConfig.operatorExclusions" 
                            :fieldExclusions="props.filterConfig.fieldExclusions" ref="filterComponentRef" />
                    </template>
                </PopoverOverlay>
            </span>
        </div>
        <div v-else class="d-inline-flex">
            <Skeleton width="3rem" height="2rem" class="me-2"></Skeleton>
            <Skeleton width="3rem" height="2rem" class="me-2"></Skeleton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, type PropType } from 'vue';
import type { FilterConfig } from "@/helpers/interface/general"
import FilterComponent from "@/components/Shared/FilterComponent.vue"
import PopoverOverlay from "@/components/Shared/PopoverOverlay.vue"
import Skeleton from 'primevue/skeleton';

const props = defineProps({
    loading: {
        type: Boolean,
        required: true
    },
    sortOrder: {
        type: String,
        required: true
    },
    filterConfig: {
        type: Object as PropType<FilterConfig | null>,
        required: false
    },
    parentDataView: {
        type: null,
        required: true
    }
})

const emits = defineEmits(["changeSortOrder", "handleSubmitFilters", "handleUpdateFilters", "onFiltersDirtyChanged"])
const changeSortOrder = () => emits("changeSortOrder", props.sortOrder)


// Filter overlay (popup)
const filterComponentRef = ref<typeof FilterComponent | null>(null)
const isFilterPopoverVisible = ref(false)
const overlayStyle = ref({})
const overlayDiv = ref<HTMLElement | null>(null)
const filterButton = ref<HTMLElement | null>(null)
const toggleOverlay = () => {
    isFilterPopoverVisible.value = !isFilterPopoverVisible.value
    if (isFilterPopoverVisible.value) {
        setFilterDivPosition()
    }
}
const handleSubmitFilters = (newFilters: any) => emits("handleSubmitFilters", newFilters)
const handleUpdateFilters = () => emits("handleUpdateFilters")
const onFiltersDirtyChanged = (value: any) => emits("onFiltersDirtyChanged", value)
const setFilterDivPosition = () => {
    if (filterButton.value && props.parentDataView) {
        const filterButtonRect = filterButton.value.getBoundingClientRect()
        const appVetDataViewRect = props.parentDataView.getBoundingClientRect()
        overlayStyle.value = {
            position: 'absolute',
            top: `${filterButtonRect.bottom + 15}px`,
            right: `${appVetDataViewRect.right - filterButtonRect.right + 10}px`
        }
    }
}

const handleClickOutside = (event: MouseEvent) => {
    if (
        overlayDiv.value &&
        !overlayDiv.value.contains(event.target as Node) &&
        !document.querySelector(".p-select-list-container")?.contains(event.target as Node) &&
        !document.querySelector(".p-select-overlay")?.contains(event.target as Node) &&
        !document.querySelector(".p-multiselect-overlay")?.contains(event.target as Node) &&
        !document.querySelector(".p-datepicker-panel")?.contains(event.target as Node)
    ) {

        // This is for the last index of the filter fields
        if (!document.contains(event.target as Node)) return

        // 
        isFilterPopoverVisible.value = false
    }
}

const clearFilters = () => {
    filterComponentRef.value?.clearFilters()
}

defineExpose({
    clearFilters
})

onMounted(() => {
    setFilterDivPosition()
    document.addEventListener('click', handleClickOutside)
})


onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside)
})

</script>