<template>
    <div v-if="isLoading" class="p-4">
        <LoadingIcon />
    </div>
    
    <div v-else class="mx-auto" style="max-width: 1100px;">
        <iframe v-if="dashboardUrl" :src="dashboardUrl" height="1200" width="1100"></iframe>
        
        <div v-else style="padding: 1em;">
            <Message severity="info" :closable="false">
                Please <a href="mailto:support@trueroll.io" style="text-decoration: underline;">contact TrueRoll support</a> to enable this feature. 
            </Message>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import { getApiErrorMessage } from "@/helpers/common"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import Message from "primevue/message"
import { InternalSettings } from "@/helpers/common"


const api = useAPI()

const dashboardUrl = ref("")
const isLoading = ref(true)


const fetchDashboardUrlSetting = () => {
    isLoading.value = true

    api.get(`/settings/internal/${InternalSettings.appvetter_dashboard_url}`)?.then(response => {
        dashboardUrl.value = response.data.value
    })?.catch(error => {
        toast.error(getApiErrorMessage(error))
    })?.finally(() => {
        isLoading.value = false
    })
}


onMounted(() => {
    fetchDashboardUrlSetting()
})
</script>