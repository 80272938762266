<template>
    <div id="parcel">
        <table class="table">
            <tbody v-if="!fetchParcelLoading">
                <tr v-if="parcel?.filing_late_application !== null">
                    <td class="label">Filing Late Application</td>
                    <td class="value">{{ parcel?.filing_late_application ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.living_on_property !== null">
                    <td class="label">Own & Live in Property</td>
                    <td class="value">{{ parcel?.living_on_property ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.co_op_housing !== null">
                    <td class="label">Own Stock In Co-op</td>
                    <td class="value">{{ parcel?.co_op_housing ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.co_op_housing_name !== null">
                    <td class="label">Co-op Name</td>
                    <td class="value">{{ parcel?.co_op_housing_name }}</td>
                </tr>
                <tr v-if="parcel?.receiving_exemption_previous_residence !== null">
                    <td class="label">Receiving Homestead on Previous Residence</td>
                    <td class="value">{{ parcel?.receiving_exemption_previous_residence ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.transferring_exemption_previous_residence !== null">
                    <td class="label">Transferring Exemption</td>
                    <td class="value">{{ parcel?.transferring_exemption_previous_residence ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.transferring_tax_limitation !== null">
                    <td class="label">Transferring Tax Limitation</td>
                    <td class="value">{{ parcel?.transferring_tax_limitation ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.is_veteran_disability_permanent !== null">
                    <td class="label">Permanent Veteran Disability</td>
                    <td class="value">{{ parcel?.is_veteran_disability_permanent ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.percent_disability_rating !== null">
                    <td class="label">Percent Disability Rating</td>
                    <td class="value">{{ parcel?.percent_disability_rating }}</td>
                </tr>
                <tr v-if="parcel?.deceased_spouse_name !== null">
                    <td class="label">Name of Deceased Spouse</td>
                    <td class="value">{{ parcel?.deceased_spouse_name }}</td>
                </tr>
                <tr v-if="parcel?.deceased_spouse_birthdate !== null">
                    <td class="label">Birthdate of Deceased Spouse</td>
                    <td class="value">{{ formatDate(parcel?.deceased_spouse_birthdate) }}</td>
                </tr>
                <tr v-if="parcel?.property_owner_name_1 !== null">
                    <td class="label">Property Owner/Applicant</td>
                    <td class="value">{{ parcel?.property_owner_name_1 }}</td>
                </tr>
                <tr v-if="parcel?.applicant_mailing_address_different !== null">
                    <td class="label">Different Mailing Address</td>
                    <td class="value">{{ parcel?.applicant_mailing_address_different ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.property_acres_occupied !== null">
                    <td class="label">Date Property Acquired</td>
                    <td class="value">{{ formatDate(parcel?.property_acres_occupied) }}</td>
                </tr>
                <tr v-if="parcel?.property_date_occupied !== null">
                    <td class="label">Date Began Occupying</td>
                    <td class="value">{{ formatDate(parcel?.property_date_occupied) }}</td>
                </tr>
                <tr v-if="parcel?.property_legal_description !== null">
                    <td class="label">Legal Description</td>
                    <td class="value">{{ parcel?.property_legal_description }}</td>
                </tr>
                <tr v-if="parcel?.applicant_identified_on_deed !== null">
                    <td class="label">Applicant on Deed</td>
                    <td class="value">{{ parcel?.applicant_identified_on_deed ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.court_filing_number !== null">
                    <td class="label">Deed Filing Number</td>
                    <td class="value">{{ parcel?.court_filing_number }}</td>
                </tr>
                <tr v-if="parcel?.heir_property_owners_occupy_property !== null">
                    <td class="label">Heir Property</td>
                    <td class="value">{{ parcel?.heir_property_owners_occupy_property }}</td>
                </tr>
                <tr v-if="parcel?.other_owned_properties !== null">
                    <td class="label">Other Heirs Occupy Property</td>
                    <td class="value">{{ parcel?.other_owned_properties }}</td>
                </tr>
                <tr v-if="parcel?.property_produces_income !== null">
                    <td class="label">Property Income Producing</td>
                    <td class="value">{{ parcel?.property_produces_income ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.percent_producing_income !== null">
                    <td class="label">Income Producing Percentage</td>
                    <td class="value">{{ parcel?.percent_producing_income }}</td>
                </tr>
                <tr v-if="parcel?.property_manufactured_home !== null">
                    <td class="label">Is Manufactured Home</td>
                    <td class="value">{{ parcel?.property_manufactured_home ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="parcel?.manufactured_home_make !== null">
                    <td class="label">Manufactured Home Make</td>
                    <td class="value">{{ parcel?.manufactured_home_make }}</td>
                </tr>
                <tr v-if="parcel?.manufactured_home_model !== null">
                    <td class="label">Manufactured Home Model</td>
                    <td class="value">{{ parcel?.manufactured_home_model }}</td>
                </tr>
                <tr v-if="parcel?.manufactured_home_id !== null">
                    <td class="label">Manufactured Home ID</td>
                    <td class="value">{{ parcel?.manufactured_home_id }}</td>
                </tr>
                <tr v-if="parcel?.property_acres_occupied !== null">
                    <td class="label">Acres Occupied</td>
                    <td class="value">{{ parcel?.property_acres_occupied }}</td>
                </tr>
                <tr v-if="parcel?.waive_reason_active_duty !== null">
                    <td class="label">Waiver of Documentation</td>
                    <td class="value">{{ parcel?.waive_reason_active_duty ? "Yes" : "No" }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in 6" :key="i">
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td>
                        <Skeleton height="1.2rem" width="250px"></Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import type { Parcel } from "@/helpers/interface/appvet"
import type { AxiosError } from "axios"
import { onMounted, ref } from "vue";
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { getApiErrorMessage, formatDate } from "@/helpers/common";
import { toast } from "@/helpers/toast";

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})

const api = useAPI()
const fetchParcelLoading = ref(true)
const parcel = ref<Parcel | null>(null)
const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}
const fetchParcel = async () => {
    const tru_id = props.tru_id
    fetchParcelLoading.value = true

    try {
        const { data } = await api.get(`applications/${tru_id}/parcel`)
        parcel.value = data
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }

    fetchParcelLoading.value = false
}

onMounted(() => fetchParcel())
</script>
