<template>
    <div id="summary-details">
        <table class="table">
            <tbody v-if="!fetchSummaryDetailLoading">
                <tr>
                    <td class="label">Application ID</td>
                    <td class="value">{{ summaryDetail?.application_id }}</td>
                </tr>
                <tr>
                    <td class="label">Parcel Number</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.situs_parcel_id }}
                        <span class="clipboard-copy-target" :id="'situs_parcel_id_' + summaryDetail?.tru_id || ''"
                            :data-copy-text="summaryDetail?.situs_parcel_id"></span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Situs Address</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.situs_full }}
                        <span class="clipboard-copy-target" :id="'situs_full_' + summaryDetail?.tru_id || ''"
                            :data-copy-text="summaryDetail?.situs_full"></span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Applicant</td>
                    <!-- nosemgrep - value is sanitized -->
                    <td class="value text-uppercase" v-html="applicantNamesDisplayHTML"></td>
                </tr>
                <tr>
                    <td class="label">Mail Address</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.mail_full }}
                        <span class="clipboard-copy-target" :id="'mail_full_' + summaryDetail?.tru_id || ''"
                            :data-copy-text="summaryDetail?.mail_full"></span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Previous Address</td>
                    <td class="value">{{ summaryDetail?.prev_situs_full }}</td>
                </tr>
                <tr>
                    <td class="label">Exemptions</td>
                    <td class="value">{{ summaryDetail?.exemptions?.join(", ") }}</td>
                </tr>
                <tr>
                    <td class="label">Submission Date</td>
                    <td class="value">{{ formatDate(summaryDetail?.application_timestamp) }}</td>
                </tr>
                <tr>
                    <td class="label">Effective Date</td>
                    <td class="value">{{ formatDate(summaryDetail?.effective_date) }}</td>
                </tr>
                <tr>
                    <td class="label">Status</td>
                    <td class="value text-capitalize">
                        <Chip :label="summaryDetail?.application_status"
                            :class="`tr-chip status-chip-${summaryDetail?.application_status}`" />
                    </td>
                </tr>
                <tr>
                    <td class="label">Assigned To</td>
                    <td class="value">
                        <Chip v-if="summaryDetail?.assigned_user" :label="summaryDetail?.assigned_user"
                            class="tr-chip assigned-to-chip" />
                        <span v-else>
                            <i>Unassigned</i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Labels</td>
                    <td class="value">
                        <Chip v-for="label in summaryDetail?.labels" :key="label" :label="label"
                            class="me-2 mb-1 tr-chip label-chip" />
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in 8" :key="i">
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td>
                        <Skeleton height="1.2rem" width="250px"></Skeleton>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td class="d-flex">
                        <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                        <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                        <Skeleton height="1.2rem" width="100px" class="me-2"></Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import type { SummaryDetail } from "@/helpers/interface/appvet"
import type { AxiosError } from "axios"
import { computed, onMounted, ref } from "vue";
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import Chip from 'primevue/chip'
import { convertLineBreaksToHTML, getApiErrorMessage, formatDate, sanitizeHTML, buildCopyToClipboardButton } from "@/helpers/common";
import { toast } from "@/helpers/toast";

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})
const emits = defineEmits(["summaryLoaded"])
const api = useAPI()
const fetchSummaryDetailLoading = ref(true)
const summaryDetail = ref<SummaryDetail | null>(null)
const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}
const fetchSummaryDetail = async () => {
    fetchSummaryDetailLoading.value = true

    try {
        const { data } = await api.get(`applications/${props.tru_id}/detail`)
        summaryDetail.value = data
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }

    fetchSummaryDetailLoading.value = false
    emits("summaryLoaded")
    appendCopyButton()

}

const appendCopyButton = () => setTimeout(() => buildCopyToClipboardButton(), 1000)
const applicantNamesDisplayHTML = computed(() => {
    const names = summaryDetail.value?.applicants_names
    if (!names) {
        return ""
    }
    return sanitizeHTML(convertLineBreaksToHTML(names))
})

onMounted(() => fetchSummaryDetail())
defineExpose({ fetchSummaryDetail })
</script>