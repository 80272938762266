<template>
  <div id="ingestion-list-container"></div>
</template>

<script setup lang="ts">
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import {onMounted, onUnmounted} from "vue";
import type {Ingest,} from "@/helpers/interface/onboarding";
import type {AjaxConfig, CellComponent, ColumnDefinition, TabulatorFull as Tabulator} from "tabulator-tables";
import {createAjaxConfig, createAjaxTabulator, destroyTabulator} from "@/helpers/true-tabulator";
import {useAuth0} from "@auth0/auth0-vue";

const { getAccessTokenSilently } = useAuth0()

const baseURL = import.meta.env.VITE_DEV_API_URL;

let tabulator: Tabulator | null;
let ajaxConfig: AjaxConfig;

const ingestionsListColumns: ColumnDefinition[] = [
  {
    title: "ID",
    field: "id",
    width: "20%",
    titleFormatter: "plaintext",
    headerSortTristate: true,
    headerFilter: "input",
    headerFilterParams: {"search": true},
    formatter: function (cell: CellComponent) {
      const data = cell.getData() as Ingest;
      const id = data.id
      return `<a target="_blank" class="candidate-list-link" href="/onboarding/ingestions/${id}">${id}</a>`;
    },
  },
  {
    title: "Config ID",
    field: "config_id",
    width: "20%",
    headerSortTristate: true,
    headerFilter: "input",
    formatter: function (cell: CellComponent) {
      const data = cell.getData() as Ingest;
      const config_id = data.config_id

      if (config_id.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i))
        return `<a target="_blank" class="candidate-list-link" href="/onboarding/configs/${config_id}">${config_id}</a>`;
      return config_id
    },
  },
  {
    title: "Customer ID",
    field: "customer_id",
    formatter: "plaintext",
    headerSortTristate: true,
    headerFilter: "number",
    headerFilterParams: {"min": 0},
  },
  {
    title: "Created",
    field: "created_at",
    width: "15%",
    headerSortTristate: true,
    headerFilter: "date",
    formatter: function (cell: CellComponent) {
      const data = cell.getData() as Ingest;
      return new Date(data.created_at).toUTCString();
    },
  },
  {
    title: "Started",
    field: "started_at",
    width: "15%",
    headerSortTristate: true,
    headerFilter: "date",
    formatter: function (cell: CellComponent) {
      const data = cell.getData() as Ingest;
      if (data.started_at)
        return new Date(data.started_at).toUTCString();
      return 'None'
    }
  },
  {
    title: "Finished",
    field: "finished_at",
    width: "15%",
    headerSortTristate: true,
    headerFilter: "date",
    formatter: function (cell: CellComponent) {
      const data = cell.getData() as Ingest;
      if (data.finished_at)
        return new Date(data.finished_at).toUTCString();
      return 'None'
    },
  },
  {
    title: "Status",
    field: "status",
    formatter: "plaintext",
    headerSortTristate: true,
    headerFilter: "list",
    headerFilterParams: {
      "values": ["pending", "running", "completed", "failed", "canceled"],
      "clearable": true
    },
  },
]

const getDataIngestions = async () => {
  if (tabulator) {
    tabulator.clearAlert()
    return
  }

  tabulator = await createAjaxTabulator("#ingestion-list-container", {
    ajaxConfig: ajaxConfig,
    columns: ingestionsListColumns,
    height: "100vh",
    placeholder: "There are no ingestions to display",
    pagination: true,
  })

  tabulator.on("tableBuilt", () => {
    if (!tabulator) {
      return
    }

    tabulator.setSort([{"column": "created_at", "dir": "desc"}])
    tabulator.setData(`${baseURL}/onboarding/ingestions/list/`)
  })
}

onMounted(async () => {
  const accessToken = await getAccessTokenSilently()
  ajaxConfig = createAjaxConfig(accessToken)
  await getDataIngestions();
});
onUnmounted(() => destroyTabulator(tabulator))

</script>

<style>
#ingestion-list-container .tabulator-header-filter input:is([type=number], [type=date]) {
  border: 3px solid var(--main-green-color)
}
</style>